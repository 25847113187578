import {
  Box,
  HStack,
  useRadio,
  useRadioGroup,
  UseRadioGroupReturn,
  UseRadioProps
} from '@chakra-ui/react';
import {FC, useCallback, useEffect} from 'react';
import {useGetRoleTagsLazyQuery} from 'generated/graphql';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faStar} from '@fortawesome/free-solid-svg-icons';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {TAGS_MAP} from 'utils/constants';
import {useRouter} from 'next/router';

interface CustomRadioProps extends UseRadioProps {
  primaryColor: string;
  contrastColor: string;
  icon: IconProp;
}

const CustomRadio: FC<React.PropsWithChildren<CustomRadioProps | undefined>> = (props) => {
  const {getInputProps, getCheckboxProps} = useRadio(props);
  const input = getInputProps();
  const checkbox = getCheckboxProps();

  const {primaryColor, contrastColor, icon} = props;

  const handleSelect = useCallback(() => {
    if (props.isChecked && input.onChange) {
      // eslint-disable-next-line @typescript-eslint/no-extra-semi
      (input.onChange as UseRadioGroupReturn['onChange'])('');
    }
  }, [input.onChange, props.isChecked]);

  return (
    <Box as="label" marginTop="8px !important">
      <input
        {...input}
        onClick={handleSelect}
        onKeyDown={(e) => {
          if (e.key !== ' ') return;
          if (props.isChecked) {
            e.preventDefault();
            handleSelect();
          }
        }}
      />
      <HStack
        {...checkbox}
        padding="8px 15px"
        textAlign="center"
        borderRadius="30px"
        background="transparent"
        fontSize={12}
        fontWeight="500"
        color={primaryColor}
        border={`1px solid ${primaryColor}`}
        _hover={{
          background: primaryColor,
          color: contrastColor,
          cursor: 'pointer'
        }}
        _checked={{bg: primaryColor, color: contrastColor}}
        spacing={2}
      >
        <FontAwesomeIcon icon={icon} />
        <span>{props.children}</span>
      </HStack>
    </Box>
  );
};

interface TagsSelectorProps {
  onChange: (slug: string) => void;
  addLiveFilter?: boolean;
}

export default function TagsSelector(props: TagsSelectorProps) {
  const [getTags, {data}] = useGetRoleTagsLazyQuery();
  const router = useRouter();

  useEffect(() => {
    getTags();
  }, [getTags]);

  // it should only run on page load.
  // ignoring router query change to avoid infity loop
  useEffect(() => {
    if (router.query.tag != undefined) {
      props.onChange(router.query.tag as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onChange]);

  const onChange = useCallback(
    (nextValue: string) => {
      if (props.onChange) {
        router.push(
          {
            href: router.pathname,
            query: {
              ...router.query,
              tag: nextValue
            }
          },
          undefined,
          {
            scroll: false
          }
        );
        props.onChange(nextValue);
      }
    },
    [props, router]
  );

  const {getRootProps, getRadioProps} = useRadioGroup({
    name: 'tag',
    onChange: onChange,
    defaultValue: router.query.tag != undefined ? (router.query.tag as string) : ''
  });

  if ((data?.tags || []).length === 0) {
    return null;
  }

  return (
    <Box {...getRootProps()} width="100%">
      <HStack spacing={2} width="100%" marginTop={-2} wrap="wrap">
        {data?.tags?.map((tag) => {
          const map = TAGS_MAP.find((t) => t.slug === tag.slug);
          return (
            <CustomRadio
              key={tag.slug}
              primaryColor={map?.primaryColor || '#fff'}
              contrastColor={map?.contrastColor || '#000'}
              icon={map?.icon || faStar}
              {...getRadioProps({value: tag.slug})}
            >
              {tag.name}
            </CustomRadio>
          );
        })}
      </HStack>
    </Box>
  );
}
