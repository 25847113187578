import React, {useMemo} from 'react';
import {Box, Flex, Heading, HStack, SystemStyleObject, Text, VStack} from '@chakra-ui/react';
import CheckIndicator from 'components/CheckIndicator';
import WaitlistIndicator from 'components/WaitlistIndicator';
import CourseRoleTag from 'components/CourseRoleTag';
import Link from 'next/link';
import Image from 'next/legacy/image';
import {PUBLIC_ENV} from 'utils/env/getter';
import {imgproxyOptimizer} from 'utils/images/imageOptimization/imgproxyOptimizer';
import {CoursesByPlan} from 'generated/graphql';

interface ThumbnailCardProps {
  backgroundImageUrl?: string;
  description?: string;
  isWaitlisted?: boolean;
  isCompleted?: boolean;
  href?: string;
  course?: CoursesByPlan;
  tags: {
    name: string;
    slug: string;
  }[];
  title: string;
}

const ThumbnailCard: React.FC<ThumbnailCardProps> = ({
  backgroundImageUrl,
  description,
  isWaitlisted = false,
  isCompleted = false,
  tags,
  title,
  course,
  href = '#'
}) => {
  const hoverFocusStyle: SystemStyleObject = {
    transform: 'scale(1.02)'
  };

  const tagsComponents = useMemo(
    () => tags?.map((tag) => <CourseRoleTag key={tag.name} name={tag.name} slug={tag.slug} />),
    [tags]
  );

  const imageUrl = useMemo(() => {
    if (backgroundImageUrl) {
      return backgroundImageUrl;
    }

    return course?.school?.slug
      ? `/images/backgrounds/${course.school.slug}.png`
      : PUBLIC_ENV.courseImagePlaceholder;
  }, [backgroundImageUrl, course]);

  return (
    <Link href={href} legacyBehavior passHref>
      <Flex
        alignItems="flex-start"
        position="relative"
        cursor="pointer"
        flexDirection="column"
        height="300px"
        transition="all 0.1s ease-in-out"
        _hover={hoverFocusStyle}
        _focusWithin={hoverFocusStyle}
        width="100%"
        padding={6}
        maxWidth="450px"
      >
        <Image
          placeholder="blur"
          blurDataURL={PUBLIC_ENV.courseImagePlaceholder}
          loader={imgproxyOptimizer}
          layout="fill"
          objectFit="cover"
          src={imageUrl}
          alt={`${title} banner`}
          style={{zIndex: 0}}
        />
        <Box
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          background="linear-gradient(rgba(15, 16, 21, 0.2) 0%, rgba(15, 16, 21, 0.7) 45%, rgba(15, 16, 21, 0.8) 55%, rgba(15, 16, 21, 0.9) 100%)"
          zIndex={50}
        />
        <VStack
          justifyContent={isCompleted || isWaitlisted ? 'space-between' : 'flex-end'}
          alignItems="flex-start"
          zIndex={100}
          height="100%"
          width="100%"
        >
          {isCompleted ? <CheckIndicator /> : isWaitlisted ? <WaitlistIndicator /> : null}
          <VStack alignItems="flex-start" spacing={1}>
            <HStack spacing={4}>{tagsComponents}</HStack>
            <Heading as="h4" fontSize="xl" textTransform="none">
              {title}
            </Heading>
            {description && <Text fontSize="sm">{description}</Text>}
          </VStack>
        </VStack>
      </Flex>
    </Link>
  );
};

export default ThumbnailCard;
