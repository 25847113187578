import {Tag, TagLabel, VStack} from '@chakra-ui/react';
import React from 'react';

const WaitlistIndicator: React.FC = () => {
  return (
    <VStack alignItems="flex-end" width="100%">
      <Tag backgroundColor="blue.400" borderRadius="100px">
        <TagLabel textColor="white" fontSize="1rem" lineHeight="1.75rem" textTransform="uppercase">
          On Waitlist
        </TagLabel>
      </Tag>
    </VStack>
  );
};

export default WaitlistIndicator;
