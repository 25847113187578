import {useCallback, useRef} from 'react';

// Dependency list includes `cb`
// it's imperative that `cb` has referential equality
/* eslint-disable-next-line */
export default function useDebounce<T = any>(
  cb: (d: T) => void,
  wait = 10
): ReturnType<typeof useCallback> {
  const debounceTimeout = useRef<ReturnType<typeof setTimeout>>();
  const debouncer = useCallback(
    (data: T) => {
      clearTimeout(debounceTimeout.current as unknown as number);
      debounceTimeout.current = setTimeout(() => {
        cb(data);
      }, wait);
    },
    [wait, cb]
  );

  return debouncer;
}
