import React, {FC} from 'react';
import {Box, CircularProgress} from '@chakra-ui/react';

interface Props {
  'aria-label'?: string;
  size?: string;
  thickness?: number;
  color?: string;
}

const Loader: FC<React.PropsWithChildren<Props>> = ({
  size = '10rem',
  thickness = 0.15,
  color = 'brand.500',
  ...props
}) => (
  <Box w="full" textAlign="center" maxH="12.5rem">
    <CircularProgress
      isIndeterminate
      size={size}
      thickness={thickness}
      color={color}
      aria-label={props['aria-label'] ?? 'Loading'}
    />
  </Box>
);

export default Loader;
