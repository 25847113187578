import {FC, useEffect, useMemo, useState} from 'react';
import {VStack, HStack, Text, SimpleGrid, Center, Stack} from '@chakra-ui/react';
import ThumbnailCard from 'components/Cards/ThumbnailCard';
import {buildDateStringWithTimeFromStartDateAndStartTime, formatDate} from 'utils/dates/formatDate';
import TabLayout from 'components/Layouts/TabLayout';
import SearchInput from 'components/SearchInput';
import {
  useGetCoursesForCatalogLazyQuery,
  CourseTypeEnum,
  CoursesByPlanBoolExp,
  CohortTypeEnum,
  CoursesByPlan,
  useGetUpcomingCohortsLazyQuery,
  CoursesBoolExp,
  Cohorts
} from 'generated/graphql';
import EmptySection from 'components/EmptySection';
import TagsSelector from 'components/Tags/TagSelector';
import Loader from 'components/Loader';
import {mediaDbUriToCdnConverter} from 'utils/media/mediaConverters';
import OutlineCheckbox from 'components/Checkboxes/OutlineCheckbox';
import {faVideo} from '@fortawesome/pro-thin-svg-icons';
import moment from 'moment';
import CohortCardTimeline from 'components/Cards/CohortCard/timeline';
import {coursePath} from 'utils/routeFactory';
import {DATE_FORMAT_DISCOVER_CARD, TIME_FORMAT} from 'utils/constants';

interface RenderCourseListProps {
  courseType: CourseTypeEnum;
  renderAsUpcomming?: boolean;
}

const RenderCourseList: FC<React.PropsWithChildren<RenderCourseListProps>> = (props) => {
  const [getCourses, {data: coursesData, loading: loadingCourses}] =
    useGetCoursesForCatalogLazyQuery();
  const [getCohorts, {data: cohortsData, loading: loadingCohorts}] =
    useGetUpcomingCohortsLazyQuery();

  const [searchTerm, setSearchTerm] = useState('');
  const [tag, setTag] = useState('');
  const [live, setLive] = useState(false);

  const courseFilters = useMemo(() => {
    const filters: CoursesByPlanBoolExp = {
      courseType: {_eq: props.courseType}
    };

    if (searchTerm) {
      filters['name'] = {_ilike: `%${searchTerm}%`};
    }

    if (tag) {
      filters['tags'] = {
        tag: {
          slug: {_eq: tag}
        }
      };
    }

    if (live) {
      filters['cohortType'] = {_eq: CohortTypeEnum.LIVE};
    }

    return filters;
  }, [props.courseType, searchTerm, tag, live]);

  const cohortFilters = useMemo(() => {
    const courseFilter: CoursesBoolExp = {
      type: {_eq: props.courseType},
      is_permitted: {_eq: true}
    };

    if (searchTerm) {
      courseFilter['name'] = {_ilike: `%${searchTerm}%`};
    }

    if (tag) {
      courseFilter['tags'] = {
        tag: {
          slug: {_eq: tag}
        }
      };
    }

    return {
      type: {_eq: CohortTypeEnum.LIVE},
      endDate: {_gt: moment().utc().format()},
      course: courseFilter
    };
  }, [props.courseType, searchTerm, tag]);

  useEffect(() => {
    if (props.renderAsUpcomming) {
      getCohorts({
        variables: {where: cohortFilters}
      });
    } else {
      getCourses({
        variables: {filter: courseFilters}
      });
    }
  }, [getCourses, getCohorts, courseFilters, cohortFilters, props.renderAsUpcomming]);

  const coursesCards = useMemo(() => {
    return coursesData?.courses.map((course) => {
      const tags = course.tags?.map((item) => item.tag);
      let description: string | undefined;

      if (
        course.courseType === CourseTypeEnum.LIGHTNING_SESSION ||
        course.name?.toLowerCase().includes('lightning')
      ) {
        if (course.nextCohortStartDate || course.firstClassStartTime) {
          description = formatDate(
            new Date(
              buildDateStringWithTimeFromStartDateAndStartTime(
                course.firstClassStartTime || course.nextCohortStartDate,
                course.firstClassStartTime || course.nextCohortStartTimeOfDay
              )
            ),
            `${DATE_FORMAT_DISCOVER_CARD} [@] ${TIME_FORMAT}`
          );
        }
      } else if (course.nextCohortStartDate === null) {
        description = 'On Demand';
      } else {
        description = `Starts on ${formatDate(
          new Date(
            buildDateStringWithTimeFromStartDateAndStartTime(
              course.firstClassStartTime || course.nextCohortStartDate,
              course.firstClassStartTime || course.nextCohortStartTimeOfDay
            )
          ),
          DATE_FORMAT_DISCOVER_CARD
        )} | On Demand`;
      }

      return (
        <ThumbnailCard
          backgroundImageUrl={
            course.thumbnailCardUrl ? mediaDbUriToCdnConverter(course.thumbnailCardUrl) : undefined
          }
          key={course.id}
          title={course.name || ''}
          course={course as CoursesByPlan}
          description={description}
          tags={
            tags as {
              name: string;
              slug: string;
            }[]
          }
          href={coursePath().courseDetails(course?.slug)}
        />
      );
    });
  }, [coursesData?.courses]);

  const cohortCards = useMemo(() => {
    if ((cohortsData?.cohorts?.length || 0) > 0) {
      return <CohortCardTimeline cohorts={cohortsData?.cohorts as Cohorts[]} linkToCourse />;
    } else {
      return null;
    }
  }, [cohortsData?.cohorts]);

  return (
    <VStack spacing={8} flexGrow={1}>
      <Stack width="100%" direction={{base: 'column', md: 'row'}} spacing={{base: 4, md: 2}}>
        <HStack width="100%" gap="1.5rem">
          <TagsSelector onChange={setTag} />
          {props.courseType === CourseTypeEnum.TRADITIONAL && !props.renderAsUpcomming && (
            <OutlineCheckbox title="Live" icon={faVideo} onChecked={setLive} />
          )}
        </HStack>
        <SearchInput onSearch={setSearchTerm} />
      </Stack>
      {(coursesCards?.length || 0) > 0 || cohortCards ? (
        <SimpleGrid
          spacing={6}
          minChildWidth={props.renderAsUpcomming ? undefined : {md: '350px', base: '300px'}}
          width="100%"
        >
          {props.renderAsUpcomming ? cohortCards : coursesCards}
        </SimpleGrid>
      ) : (
        <Center minHeight={400}>
          {loadingCourses || loadingCohorts ? (
            <VStack spacing={4}>
              <Loader size="5rem" />
              <Text>
                {props.courseType === CourseTypeEnum.TRADITIONAL
                  ? 'Loading courses...'
                  : 'Loading sessions...'}
              </Text>
            </VStack>
          ) : (
            <EmptySection
              title={
                props.courseType === CourseTypeEnum.TRADITIONAL
                  ? 'No courses found 🙈'
                  : 'No sessions found 🙈'
              }
              subtitle={
                props.courseType === CourseTypeEnum.TRADITIONAL
                  ? 'Try to change the filters or search for a specific course'
                  : 'Try to change the filters or search for a specific session'
              }
            />
          )}
        </Center>
      )}
    </VStack>
  );
};

interface DiscoverProps {
  renderAsUpcomming?: boolean;
}

const Discover: FC<React.PropsWithChildren<DiscoverProps>> = (props) => {
  return (
    <VStack width="100%" flexGrow={1} maxWidth={1200} marginX="auto" padding="0 16px">
      <TabLayout
        title={props.renderAsUpcomming ? 'Upcoming' : 'Discover'}
        tabs={
          props.renderAsUpcomming
            ? [
                {
                  title: 'Live Coaching',
                  content: (
                    <RenderCourseList
                      courseType={CourseTypeEnum.LIGHTNING_SESSION}
                      renderAsUpcomming={true}
                    />
                  )
                }
              ]
            : [
                {
                  title: 'Live Coaching',
                  content: (
                    <RenderCourseList
                      courseType={CourseTypeEnum.LIGHTNING_SESSION}
                      renderAsUpcomming={false}
                    />
                  )
                },
                {
                  title: 'Courses',
                  content: (
                    <RenderCourseList
                      courseType={CourseTypeEnum.TRADITIONAL}
                      renderAsUpcomming={false}
                    />
                  )
                }
              ]
        }
      />
    </VStack>
  );
};

export default Discover;
