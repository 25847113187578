import {SearchIcon} from '@chakra-ui/icons';
import {HStack, Input, IconButton} from '@chakra-ui/react';
import useDebounce from 'hooks/useDebounce';

interface SearchInputProps {
  placeholder?: string;
  onSearch: (searchTerm: string) => void;
}

export default function SearchInput(props: SearchInputProps) {
  const handler = useDebounce(props.onSearch, 600);

  return (
    <HStack
      spacing={0}
      minWidth={{
        base: '100%',
        md: '300px'
      }}
    >
      <Input
        background="#202126"
        color="#fff"
        placeholder={props.placeholder ?? 'Search...'}
        borderRadius={0}
        border="none"
        paddingX={4}
        width="100%"
        outline="none"
        boxShadow="none !important"
        onChange={(event) => handler(event.target.value)}
      />
      <IconButton
        color="#fff"
        background="#202126"
        aria-label="Search courses"
        icon={<SearchIcon />}
      />
    </HStack>
  );
}
