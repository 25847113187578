import React, {FC} from 'react';
import {Box, Button, Flex, FlexProps, Text} from '@chakra-ui/react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSparkles} from '@fortawesome/pro-regular-svg-icons';
import {useDisclosure} from '@chakra-ui/hooks';
import {useAnalytics} from 'context/AnalyticsWebContext';
import CreateProgramModal from './CreateProgramModal';
import useIsManager from 'hooks/useIsManager';

const RecommendBanner: FC<FlexProps> = ({...props}) => {
  const {isOpen, onOpen, onClose} = useDisclosure();

  const isManager = useIsManager();

  const analyticsWebClient = useAnalytics();
  const programFeatureEnabled = analyticsWebClient?.checkFeatureFlag('create-program');

  if (programFeatureEnabled) {
    return (
      <>
        <Flex
          flexDirection="row"
          backgroundColor="gray.100"
          height={20}
          alignItems="center"
          pl={6}
          pr={6}
          gap={6}
          {...props}
        >
          <Flex
            justifyContent="center"
            alignItems="center"
            borderRadius={25}
            w="50px"
            h="50px"
            background="lime.200"
          >
            <FontAwesomeIcon icon={faSparkles} size="lg" color="#202126" />
          </Flex>
          <Flex flexDirection="column" flexGrow={1}>
            <Text fontSize="16px" lineHeight="16px" fontWeight="600" color="text.200" mb={2}>
              {isManager
                ? 'Match your team to the right sessions'
                : 'Discover the right session for you'}
            </Text>
            <Text fontSize="12px" lineHeight="12px" fontWeight="500" color="text.200" opacity={0.7}>
              Not sure which session to start with? Use our recommendation engine to get started!
            </Text>
          </Flex>

          <Box
            width="130px"
            height="42px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            position="relative"
            overflow="hidden"
            filter="drop-shadow(0px 4px 6px #0F1015)"
          >
            <div className="animated-button-border rotate-button-border" />
            <div className="animated-button-border-background" />
            <Button
              color="lime.200"
              _hover={{bg: 'rgba(255,255,255,0.10)'}}
              variant="ghost"
              className="animated-button"
              height="38px"
              width="126px"
              onClick={onOpen}
            >
              Get Started
            </Button>
          </Box>
        </Flex>
        <CreateProgramModal isOpen={isOpen} onClose={onClose} />
      </>
    );
  }
  return null;
};

export default RecommendBanner;
