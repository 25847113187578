import {Children, FC} from 'react';
import {Heading, TextProps} from '@chakra-ui/react';

type PageTitleProps = TextProps;

const PageTitle: FC<React.PropsWithChildren<PageTitleProps>> = ({children, ...props}) => {
  console.assert(
    Children.count(children) === 1,
    '`PageTitle` needs a title passed in as `children`'
  );
  return (
    <Heading
      fontFamily="'Inter', sans-serif"
      fontWeight="600"
      lineHeight="100%"
      marginBottom="2rem"
      size="lg"
      textTransform="capitalize"
      {...props}
    >
      {children}
    </Heading>
  );
};

export default PageTitle;
