import React, {FC, useMemo} from 'react';
import PageTitle from 'components/PageTitle';
import {Flex} from '@chakra-ui/react';
import {
  PagePanel,
  PagePanels,
  PageTab,
  PageTabList,
  PageTabs
} from 'components/Layouts/TabLayout/PageTab';
import slugify from 'slugify';
import {useRouter} from 'next/router';
import SEO from 'components/SEO/SEO';
import RecommendBanner from '../../pages/MyTeam/Programs/RecommendBanner';

type CustomTab = {
  title: string;
  content: React.ReactElement;
};

interface TabLayoutProps {
  title: string;
  tabs?: CustomTab[];
}

const TabLayout: FC<React.PropsWithChildren<TabLayoutProps>> = (props) => {
  const router = useRouter();
  const tabs = useMemo(() => props.tabs ?? [], [props.tabs]);
  const tabsHeaders = useMemo(
    () => tabs.map((tab) => <PageTab key={tab.title}>{tab.title}</PageTab>),
    [tabs]
  );
  const tabsTitles = useMemo(() => tabs.map((tab) => tab.title), [tabs]);

  const tabIndices = useMemo(() => tabs.map((tab) => slugify(tab.title).toLowerCase()), [tabs]);
  const selectedTab = useMemo(
    () => router.query.tab ?? tabIndices[0],
    [router.query.tab, tabIndices]
  ) as string;
  const selectedTabIndex = useMemo(
    () => tabIndices.indexOf(selectedTab),
    [selectedTab, tabIndices]
  );
  const selectedTabTitle = useMemo(
    () => tabsTitles[selectedTabIndex],
    [selectedTabIndex, tabsTitles]
  );

  const tabsContent = useMemo(
    () => tabs.map((tab) => <PagePanel key={tab.title}>{tab.content}</PagePanel>),
    [tabs]
  );

  // NOTE: you may see "abort fetch component for route X" in the console.
  // this is due to the fact that Chakra-UI's Tabs component calls onChange twice:
  // 1. when tab is clicked
  // 2. when the tab is focused
  // this is a known issue: https://github.com/chakra-ui/chakra-ui/issues/5552
  // One workaround is to set "isManual" prop to true, but this will break keyboard navigation (a11y)

  return (
    <>
      <SEO title={`${props.title} - ${selectedTabTitle}`} />
      <Flex flexDirection="column" width="100%" flexGrow={1} alignItems="flex-start">
        <PageTitle marginBottom={6}>{props.title}</PageTitle>
        <RecommendBanner width="100%" mb={8} />
        <PageTabs
          index={selectedTabIndex}
          onChange={(index) => {
            router.push({href: router.pathname, query: {tab: tabIndices[index]}}, undefined, {
              scroll: false
            });
          }}
          width="100%"
          margin={0}
        >
          <PageTabList>{tabsHeaders}</PageTabList>
          <PagePanels>{tabsContent}</PagePanels>
        </PageTabs>
      </Flex>
    </>
  );
};

export default TabLayout;
