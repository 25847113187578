import React, {PropsWithChildren} from 'react';
import {
  Tab,
  TabList,
  TabListProps,
  TabPanel,
  TabPanelProps,
  TabPanels,
  TabProps,
  Tabs,
  TabsProps,
  useMultiStyleConfig,
  useTab
} from '@chakra-ui/react';

export const PageTabs: React.FC<TabsProps> = ({children, ...props}) => (
  <Tabs margin={0} width="100%" isLazy {...props}>
    {children}
  </Tabs>
);

export const PageTabList: React.FC<TabListProps> = ({children, ...props}) => (
  <TabList gap={6} marginBottom={8} {...props}>
    {children}
  </TabList>
);

export const PageTab = React.forwardRef<HTMLElement, TabProps>((props, ref) => {
  const tabProps = useTab({...props, ref});
  const styles = useMultiStyleConfig('Tabs', tabProps);

  return (
    <Tab
      color="textColor.200"
      borderBottomWidth={4}
      paddingX={0}
      paddingTop={0}
      paddingBottom={6}
      lineHeight="1.25rem"
      fontSize="1.25rem"
      _selected={{borderColor: 'lime.200', color: 'textColor.100', fontWeight: 600}}
      _active={{backgroundColor: 'transparent'}}
      __css={styles.tab}
    >
      {tabProps.children}
    </Tab>
  );
});
PageTab.displayName = 'PageTab';

export const PagePanels: React.FC<PropsWithChildren> = ({children}) => (
  <TabPanels>{children}</TabPanels>
);

export const PagePanel: React.FC<TabPanelProps> = ({children, ...props}) => (
  <TabPanel padding={0} {...props}>
    {children}
  </TabPanel>
);
