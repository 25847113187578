import {chakra, HStack, Text, useCheckbox, UseCheckboxProps} from '@chakra-ui/react';
import {IconDefinition} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useEffect, useMemo} from 'react';

interface OutlineCheckboxProps extends UseCheckboxProps {
  primaryColor?: string;
  contrastColor?: string;
  icon?: IconDefinition;
  title: string;
  onChecked?: (checked: boolean) => void;
}

const OutlineCheckbox = (props: OutlineCheckboxProps) => {
  const {state, getCheckboxProps, getInputProps, getLabelProps, htmlProps} = useCheckbox(props);

  const {primaryColor, contrastColor, icon} = props;

  const checkedStyle = useMemo(() => {
    return state.isChecked
      ? {
          background: primaryColor ?? '#fff',
          color: contrastColor ?? '#000'
        }
      : {};
  }, [state.isChecked, primaryColor, contrastColor]);

  useEffect(() => {
    props.onChecked?.(state.isChecked);
  }, [state.isChecked, props]);

  return (
    <chakra.label
      padding="8px 15px"
      textAlign="center"
      borderRadius="30px"
      fontSize={12}
      fontWeight="500"
      minW={85}
      cursor="pointer"
      border={`1px solid ${primaryColor ?? '#fff'}`}
      {...checkedStyle}
      _hover={{
        background: primaryColor ?? '#fff',
        color: contrastColor ?? '#000'
      }}
      {...htmlProps}
    >
      <input {...getInputProps()} hidden />
      <HStack {...getCheckboxProps()} width="100%" justifyContent="center">
        {icon && <FontAwesomeIcon icon={icon} />}
        <Text {...getLabelProps()}>{props.title}</Text>
      </HStack>
    </chakra.label>
  );
};

export default OutlineCheckbox;
